.main-main {
    display: flex;
    align-items: center;
    align-content: center;
    z-index: -5;
    height: calc(100vh - 72px - 45px - 20px);
}

.header {
    display: flex;
    flex-direction: column;
    align-content: center;
    height: 368px;
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
}

.header .header-primary {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    padding: 0;
    margin-bottom: 12px;
    letter-spacing: 0.37px;
}

.header p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    color: rgb(128, 128, 128);
}

.header .connect {
    font-weight: 500;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #7f8cff;
    margin-bottom: 14px;
}

.header .icons-container {
    height: 20px;
}

.header .icon {
    color: #505f79;
    height: 100%;
    width: 30px;
    margin-right: 20px;
}

.header .icon:hover {
    filter: brightness(150%);
}

/* Extra small devices (phones, 500px) */
@media only screen and (min-width: 500px) {
    .header {
        max-width: 450px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .header {
        max-width: 550px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .header .header-primary {
        font-size: 48px;
        line-height: 60px;
    }

    .header p {
        font-size: 20px;
        line-height: 28px;
    }

    .header {
        max-width: 668px;
    }

    .year {
        bottom: 0;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .header {
        max-width: 800px;
    }
}
