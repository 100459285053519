.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: calc((100vh - 72px - 60vh) / 2 - 1rem) 20px;
    height: 60vh;

    img {
        max-width: 80%;
        margin: 2rem 0;
    }

    button {
        margin-bottom: 4rem;
    }
}

@media only screen and (min-width: 768px) {
    .not-found {
        img {
            max-width: 50%;
        }
    }
}
