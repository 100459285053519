section#contact {
    max-width: 600px;
    margin: 0 auto;

    form {
        margin-bottom: 4rem;

        p {
            font-size: 20px;
        }

        textarea {
            resize: vertical;
            height: 10rem;
        }

        button {
            margin-top: 0.5rem;
        }
    }

    .submitted {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 80%;
            margin-bottom: 4rem;
        }
    }
}
