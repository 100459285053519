.headerbg-mobile {
    width: 100%;
    position: absolute;
    height: 587.15px;
    max-width: 100vw;
    top: 10%;
    z-index: -2;
}

.headerbg {
    display: none;
}

.children {
    padding: 0 20px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .headerbg-mobile {
        display: none;
    }

    .headerbg {
        display: block;
        height: 721.46px;
        max-height: calc(100vh - 120px);
        max-width: 100vw;
        position: absolute;
        top: 10%;
        width: 100%;
        z-index: -2;
    }

    .year {
        bottom: 0;
    }
}
