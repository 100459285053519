section.articles {
    .articles-container {
        max-width: calc(800px + 4rem);

        .grid-row {
            margin: 0;
        }
    }

    @media (min-width: 768px) {
        .articles-container {
            max-width: calc(800px + 4rem);
            margin: 0 auto;

            .grid-row {
                display: flex;
                justify-content: center;
            }
        }
    }
}
