nav {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;

    a {
        display: flex;
        align-items: center;

        img {
            height: 50px;
            width: 50px;
            margin-right: 5px;
        }
    }

    .nav-items .mode img {
        display: flex;
        align-items: center;
        height: 25px;
        width: 25px;
        cursor: pointer;
    }
}

.nav-item {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: var(--color-light-purple);
    margin-left: 32px;
    text-decoration: none;
}

.nav-item:hover {
    filter: brightness(80%);
    cursor: pointer;
}

@media only screen and (max-width: 350px) {
    nav {
        max-width: 320px;
    }
}

/* Extra small devices (phones, 500px) */
@media only screen and (min-width: 500px) {
    nav {
        max-width: 450px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    nav {
        max-width: 550px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    nav {
        max-width: 718px;
    }

    .nav-links {
        display: flex;
    }

    .mode {
        margin-left: 20px;
    }

    .nav-items {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    nav {
        max-width: 942px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    nav {
        max-width: 1150px;
    }
}
