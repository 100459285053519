.hide-nav {
    bottom: -60px !important;
}

.nav-mobile {
    display: flex;
    position: fixed;
    transition: var(--slow-transition);
    width: 100%;
    bottom: 0;
    left: 0;
    overflow: hidden;

    .nav-link {
        display: flex;
        justify-content: center;
        font-size: 10px;
        width: calc(100% / 3);

        .icon-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                padding: 0;
            }
        }

        &.active {
            font-weight: bold;
            color: var(--mix-purple);
        }
    }
}

.nav {
    display: flex;
    width: 100%;

    .nav-link {
        font-size: 15px;
        text-align: center;
        margin-right: 25px;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            border-bottom: 5px solid var(--dark-gray);
        }
    }
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        filter: brightness(90%);
    }
}
