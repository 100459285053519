@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --light-purple: #818be0;
    --lighter-purple: #919cff;
    --shadow: #d4d4db;
    --code-one: magenta;
    --lightest-purple: #ebebff;
    --hr-line-color: #ebecf0;
    --mix-purple: #6070ff;
    --pop-up-border: #dfe1e6;
    --deep-purple: #313fb7;
    --dark-bg: #171b27;
    --lang-color-dark: #272b38;
    --darker-dark-bg: darken(--dark-bg, 20%);
    --light-bg: white;
    --darker-light-bg: darken(--light-bg, 100%);
    --dark-gray: rgb(104, 104, 104);
    --additional: #ffce00;
    --transition: 1000ms;
    --slow-transition: 400ms;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: background var(--transition);
}

section {
    transition: background var(--transition);
}

.container {
    margin: 0 auto;
    padding: 0 1.5em;
    box-sizing: border-box;
    max-width: 1260px;
    height: 100%;
    outline: none;
}

.header2 {
    text-align: center;
}

.shadow {
    -webkit-box-shadow: -1px 0 5px 0 var(--shadow);
    -moz-box-shadow: -1px 0 5px 0 var(--shadow);
    box-shadow: -1px 0 5px 0 var(--shadow);
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        'Courier New',
        monospace;
}
