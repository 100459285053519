@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
:root{--light-purple: #818be0;--lighter-purple: #919cff;--shadow: #d4d4db;--code-one: magenta;--lightest-purple: #ebebff;--hr-line-color: #ebecf0;--mix-purple: #6070ff;--pop-up-border: #dfe1e6;--deep-purple: #313fb7;--dark-bg: #171b27;--lang-color-dark: #272b38;--darker-dark-bg: darken(--dark-bg, 20%);--light-bg: white;--darker-light-bg: darken(--light-bg, 100%);--dark-gray: rgb(104, 104, 104);--additional: #ffce00;--transition: 1000ms;--slow-transition: 400ms}body{margin:0;padding:0;font-family:'Poppins', sans-serif;box-sizing:border-box;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;transition:background 1000ms;transition:background var(--transition)}section{transition:background 1000ms;transition:background var(--transition)}.container{margin:0 auto;padding:0 1.5em;box-sizing:border-box;max-width:1260px;height:100%;outline:none}.header2{text-align:center}.shadow{box-shadow:-1px 0 5px 0 #d4d4db;box-shadow:-1px 0 5px 0 var(--shadow)}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

.main-main{display:flex;align-items:center;align-content:center;z-index:-5;height:calc(100vh - 72px - 45px - 20px)}.header{display:flex;flex-direction:column;align-content:center;height:368px;width:100%;max-width:450px;margin:0 auto}.header .header-primary{font-weight:bold;font-size:30px;line-height:40px;padding:0;margin-bottom:12px;letter-spacing:0.37px}.header p{font-size:16px;line-height:24px;margin-bottom:12px;color:gray}.header .connect{font-weight:500;font-weight:bold;font-size:16px;line-height:24px;color:#7f8cff;margin-bottom:14px}.header .icons-container{height:20px}.header .icon{color:#505f79;height:100%;width:30px;margin-right:20px}.header .icon:hover{-webkit-filter:brightness(150%);filter:brightness(150%)}@media only screen and (min-width: 500px){.header{max-width:450px}}@media only screen and (min-width: 600px){.header{max-width:550px}}@media only screen and (min-width: 768px){.header .header-primary{font-size:48px;line-height:60px}.header p{font-size:20px;line-height:28px}.header{max-width:668px}.year{bottom:0}}@media only screen and (min-width: 992px){.header{max-width:800px}}

section#contact{max-width:600px;margin:0 auto}section#contact form{margin-bottom:4rem}section#contact form p{font-size:20px}section#contact form textarea{resize:vertical;height:10rem}section#contact form button{margin-top:0.5rem}section#contact .submitted{display:flex;flex-direction:column;justify-content:center;align-items:center}section#contact .submitted img{width:80%;margin-bottom:4rem}

section.articles .articles-container{max-width:calc(800px + 4rem)}section.articles .articles-container .grid-row{margin:0}@media (min-width: 768px){section.articles .articles-container{max-width:calc(800px + 4rem);margin:0 auto}section.articles .articles-container .grid-row{display:flex;justify-content:center}}

.not-found{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:calc((100vh - 72px - 60vh) / 2 - 1rem) 20px;height:60vh}.not-found img{max-width:80%;margin:2rem 0}.not-found button{margin-bottom:4rem}@media only screen and (min-width: 768px){.not-found img{max-width:50%}}

.headerbg-mobile{width:100%;position:absolute;height:587.15px;max-width:100vw;top:10%;z-index:-2}.headerbg{display:none}.children{padding:0 20px}@media only screen and (min-width: 768px){.headerbg-mobile{display:none}.headerbg{display:block;height:721.46px;max-height:calc(100vh - 120px);max-width:100vw;position:absolute;top:10%;width:100%;z-index:-2}.year{bottom:0}}

nav{margin:0 auto;display:flex;align-items:center;justify-content:space-between;padding:15px 20px}nav a{display:flex;align-items:center}nav a img{height:50px;width:50px;margin-right:5px}nav .nav-items .mode img{display:flex;align-items:center;height:25px;width:25px;cursor:pointer}.nav-item{font-weight:500;font-size:15px;line-height:20px;color:var(--color-light-purple);margin-left:32px;text-decoration:none}.nav-item:hover{-webkit-filter:brightness(80%);filter:brightness(80%);cursor:pointer}@media only screen and (max-width: 350px){nav{max-width:320px}}@media only screen and (min-width: 500px){nav{max-width:450px}}@media only screen and (min-width: 600px){nav{max-width:550px}}@media only screen and (min-width: 768px){nav{max-width:718px}.nav-links{display:flex}.mode{margin-left:20px}.nav-items{display:flex;justify-content:center;align-items:center}}@media only screen and (min-width: 992px){nav{max-width:942px}}@media only screen and (min-width: 1200px){nav{max-width:1150px}}

.hide-nav{bottom:-60px !important}.nav-mobile{display:flex;position:fixed;transition:var(--slow-transition);width:100%;bottom:0;left:0;overflow:hidden}.nav-mobile .nav-link{display:flex;justify-content:center;font-size:10px;width:calc(100% / 3)}.nav-mobile .nav-link .icon-container{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:0.5rem}.nav-mobile .nav-link .icon-container .icon{display:flex;justify-content:center;align-items:center;font-size:20px;padding:0}.nav-mobile .nav-link.active{font-weight:bold;color:var(--mix-purple)}.nav{display:flex;width:100%}.nav .nav-link{font-size:15px;text-align:center;margin-right:25px}.nav .nav-link:last-child{margin-right:0}.nav .nav-link.active{border-bottom:5px solid var(--dark-gray)}a{color:inherit;text-decoration:none}a:hover{-webkit-filter:brightness(90%);filter:brightness(90%)}

.year{display:flex;justify-content:center;padding:1rem;align-items:center;color:var(--dark-gray)}

